'use strict';

angular.module('managerApp').config(($routeProvider) => {
    $routeProvider.when('/prepaid-accounts', {
        templateUrl: 'app/prepaid-accounts/list.html',
        controller: 'PrepaidAccountsController'
                //hasPermissions: ['USERS:GET']
    }).when('/prepaid-accounts/:id', {
        templateUrl: 'app/prepaid-accounts/item.html',
        controller: 'PrepaidAccountsController'
                //hasPermissions: ['USERS:PUT', 'USERS:DELETE']
    });
});
